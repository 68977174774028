<template>
    <div :class="$style.jobsTableWrapper">
        <EmptyStateCard v-if="!jobs.length" message="No jobs open during this period" />
        <table v-else :class="$style.jobsTable">
            <thead>
                <tr>
                    <th style="width: 75%" scope="col">Job title</th>
                    <th colspan="2" style="width: 25%" scope="col">Number of applications</th>
                </tr>
            </thead>
            <tbody>
                <!-- eslint-disable vue/require-v-for-key -->
                <tr v-for="job in jobs">
                    <td>
                        {{ job.title }}
                    </td>
                    <td>
                        <a :href="jobLink(job)" :class="$style.jobLink"> View report summary </a>
                    </td>
                    <td>
                        {{ job.candidates }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="jobs.length" :class="$style.blurOverlay"></div>
    </div>
</template>

<script>
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard'

export default {
    name: 'JobsTable',
    props: {
        jobs: { type: Array, default: () => [] },
    },
    components: {
        EmptyStateCard,
    },
    methods: {
        jobLink(job) {
            return `/manage/${job.id}/reports`
        },
    },
}
</script>

<style module src="./JobsTable.css" />
