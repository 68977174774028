<template>
    <div :class="$style.timePeriodContainer">
        <div :class="$style.headerContainer">
            <h3 :class="$style.header3">Refine data based on applications submitted</h3>
            <Tooltip :class="$style.tooltip" placement="right">
                <template slot="default">
                    <FontAwesomeIcon :icon="questionCircleIcon" />
                </template>
                <template slot="inner">
                    <p>
                        Our reporting tools count all applications submitted in your chosen
                        timeframe
                    </p>
                </template>
            </Tooltip>
        </div>
        <span :class="$style.datePickerRow">
            <span :class="[$style.datePickerWrap, $style.datePickerSpacing]">
                <p :class="$style.textSmallBody">From</p>
                <DatePicker
                    v-model="range.start"
                    mode="date"
                    @input="emitDatesChanged"
                    :popover="{ visibility: disabled ? 'hidden' : 'focus' }"
                    :max-date="range.end"
                >
                    <div :class="$style.dateInputContainer">
                        <TextInput
                            :class="$style.dateInput"
                            ariaLabel="stats period date start"
                            :value="rangeStartText"
                            :disabled="disabled"
                        />
                    </div>
                </DatePicker>
            </span>
            <span :class="$style.datePickerWrap">
                <p :class="$style.textSmallBody">To</p>
                <DatePicker
                    v-model="range.end"
                    mode="date"
                    @input="emitDatesChanged"
                    :popover="{ visibility: disabled ? 'hidden' : 'focus' }"
                    :min-date="range.start"
                >
                    <div :class="$style.dateInputContainer">
                        <TextInput
                            :class="$style.dateInput"
                            ariaLabel="stats period date end"
                            :value="rangeEndText"
                            :disabled="disabled"
                        />
                    </div>
                </DatePicker>
            </span>
        </span>
    </div>
</template>

<script>
import { format as formatDate, subMonths, startOfDay, endOfDay } from 'date-fns'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { TextInput, Tooltip } from '@applied/marmot'

export default {
    name: 'TimePeriodSelectionBar',
    components: {
        DatePicker,
        FontAwesomeIcon,
        TextInput,
        Tooltip,
    },
    props: {
        disabled: Boolean,
    },
    data() {
        return {
            range: {
                start: subMonths(new Date(), 3),
                end: new Date(),
            },
        }
    },
    mounted() {
        this.emitDatesChanged()
    },
    computed: {
        questionCircleIcon() {
            return faQuestionCircle
        },
        rangeStartText() {
            return this.formatDate(this.range.start)
        },
        rangeEndText() {
            return this.formatDate(this.range.end)
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date, 'dd MMMM yyyy')
        },
        emitDatesChanged() {
            this.$emit('datesChanged', {
                startDate: startOfDay(this.range.start),
                endDate: endOfDay(this.range.end),
            })
        },
    },
}
</script>

<style module src="./TimePeriodSelectionBar.css" />
