const baseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8000'
    }
    if (process.env.NODE_ENV === 'test') {
        return 'http://test:test'
    }
    return '..'
}

export default {
    baseUrl,
}
