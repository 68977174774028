<template>
    <div :class="$style.vTabsContainer">
        <VTabs :id="$style.heatmapTabsContainer" v-model="tab">
            <VTab
                v-for="category in demographicCategories"
                :key="category"
                :class="$style.demographicTab"
                :active-class="$style.demographicTabActive"
            >
                {{ formatWithSpaces(category) }}
            </VTab>
        </VTabs>
        <VTabsItems v-model="tab">
            <VTabItem
                :transition="false"
                :reverse-transition="false"
                v-for="category in demographicCategories"
                :key="category"
            >
                <HeatmapTable
                    :table-data="getTableData(category)"
                    :table-headers="getTableHeaders(category)"
                    :category="category"
                    :maxCellValue="category === 'performance' ? 5 : 100"
                    ref="heatmap"
                >
                </HeatmapTable>
            </VTabItem>
        </VTabsItems>
        <div :class="$style.blurOverlay"></div>
    </div>
</template>

<script>
import { VTabs, VTab, VTabsItems, VTabItem } from 'vuetify/lib'
import HeatmapTable from './HeatmapTable/HeatmapTable'

export default {
    name: 'HeatmapSection',
    components: {
        VTabs,
        VTab,
        VTabsItems,
        VTabItem,
        HeatmapTable,
    },
    data() {
        return {
            tab: null,
            categoryOptionsToMoveToEnd: ['other', 'decline'],
            defaultHeaders: [
                {
                    text: 'Source',
                    align: 'start',
                    value: 'name',
                },
                {
                    text: 'Total',
                    value: 'total',
                },
            ],
        }
    },
    props: {
        diversityData: {
            type: Object,
        },
    },
    computed: {
        demographicCategories() {
            return Object.keys(this.diversityData)
        },
    },
    methods: {
        demographicCategoryOptions(category) {
            return Object.keys(
                this.referrerDataForCategory(category).reduce(
                    (result, obj) => Object.assign(result, obj),
                    {},
                ),
            ).filter((key) => this.isBucketColumn(key))
        },
        isBucketColumn(text) {
            return !['name', 'total'].includes(text)
        },
        referrerDataForCategory(category) {
            return this.diversityData[category]
        },
        formatWithSpaces(text) {
            return text.split(/(?=[A-Z])/).join(' ')
        },
        formatReferrer(referrerUtmName) {
            if (referrerUtmName.startsWith('quick-apply-')) {
                return `quick-apply / ${referrerUtmName.substring('quick-apply-'.length)}`
            }
            return referrerUtmName
        },
        getTableData(demographicCategory) {
            return this.referrerDataForCategory(demographicCategory).map((referrer) => {
                if (referrer.total < 5 && demographicCategory !== 'performance') {
                    return {
                        name: this.formatReferrer(referrer.name),
                        total: referrer.total,
                        notEnoughData: true,
                        value: 'Not enough data',
                    }
                }

                const referrerFormattedForTable = {}
                Object.keys(referrer).forEach((key) => {
                    if (key === 'avgInterviewScore') {
                        referrerFormattedForTable[key] =
                            referrer[key] || referrer[key] === 0
                                ? (referrer[key] / 100).toFixed(2)
                                : 'N/A'
                    } else if (this.isBucketColumn(key) && demographicCategory === 'performance') {
                        referrerFormattedForTable[key] = referrer[key]
                            ? referrer[key].toFixed(2)
                            : 'N/A'
                    } else if (key === 'name') {
                        referrerFormattedForTable[key] = this.formatReferrer(referrer.name)
                    } else if (!this.isBucketColumn(key)) {
                        referrerFormattedForTable[key] = referrer[key] || 'N/A'
                    } else {
                        referrerFormattedForTable[key] = (
                            (referrer[key] / referrer.total) *
                            100
                        ).toFixed(0)
                    }
                })
                return referrerFormattedForTable
            })
        },
        getTableHeaders(demographicCategory) {
            const categoryOptions = this.demographicCategoryOptions(demographicCategory)
            // Done using double filter, so we retain the ordering of the categoryOptionsToMoveToEnd in the output.
            const appendOptions = this.categoryOptionsToMoveToEnd.filter((option) =>
                categoryOptions.includes(option),
            )
            const normalOptions = categoryOptions.filter(
                (option) => !this.categoryOptionsToMoveToEnd.includes(option),
            )

            const dataHeaders = normalOptions
                .sort()
                .concat(appendOptions)
                .map((category) => ({
                    text: this.formatWithSpaces(category),
                    value: category,
                }))

            return this.defaultHeaders.concat(dataHeaders)
        },
    },
}
</script>

<style module src="./HeatmapSection.css" />
