import { updateTooltip } from '../tooltipConfig'

const config = {
    defaultOptions: {
        plugins: {
            tooltip: {
                enabled: false,
                external: (context) => {
                    updateTooltip(context, 'dropoffChartTooltip', {
                        displayAllLabels: true,
                        percentages: true,
                    })
                },
            },
            htmlLegend: {
                containerID: 'stackedChartLegend',
            },
            legend: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            margin: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    beginAtZero: true,
                    minRotation: 30,
                },
                stacked: true,
            },
            y: {
                max: 100,
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 20,
                },
                title: {
                    display: true,
                    text: 'How is each group split?',
                },
                stacked: true,
            },
        },
    },
}

export default config
