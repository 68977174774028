module.exports = {
    countryList: [
        { value: 'en-AU', text: 'Australia' },
        { value: 'en-CA', text: 'Canada' },
        { value: 'en-FR', text: 'France' },
        { value: 'en-DE', text: 'Germany' },
        { value: 'en-GB', text: 'United Kingdom' },
        { value: 'en-NI', text: 'Northern Ireland' },
        { value: 'en-HK', text: 'Hong Kong' },
        { value: 'en-IN', text: 'India' },
        { value: 'en-NL', text: 'The Netherlands' },
        { value: 'en-NZ', text: 'New Zealand' },
        { value: 'en-SG', text: 'Singapore' },
        { value: 'en-CH', text: 'Switzerland' },
        { value: 'en-US', text: 'United States' },
        { value: 'other', text: 'Other (minimal form)' },
    ],
    signupCountryList: [
        { value: 'en-AU', text: 'Australia' },
        { value: 'en-CA', text: 'Canada' },
        { value: 'en-FR', text: 'France' },
        { value: 'en-DE', text: 'Germany' },
        { value: 'en-GB', text: 'United Kingdom' },
        { value: 'en-NI', text: 'Northern Ireland' },
        { value: 'en-HK', text: 'Hong Kong' },
        { value: 'en-IN', text: 'India' },
        { value: 'en-NL', text: 'The Netherlands' },
        { value: 'en-NZ', text: 'New Zealand' },
        { value: 'en-SG', text: 'Singapore' },
        { value: 'en-CH', text: 'Switzerland' },
        { value: 'en-US', text: 'United States' },
        { value: 'other', text: 'Other' },
    ],
    timezoneOptions: [
        'Australia/Sydney',
        'Africa/Cairo',
        'Africa/Johannesburg',
        'Africa/Lagos',
        'America/Bogota',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'America/New_York',
        'America/Toronto',
        'America/Vancouver',
        'Asia/Jerusalem',
        'Asia/Kolkata',
        'Asia/Seoul',
        'Asia/Singapore',
        'Canada/Newfoundland',
        'Canada/Atlantic',
        'Canada/Eastern',
        'Canada/Central',
        'Canada/Mountain',
        'Canada/Pacific',
        'Europe/Amsterdam',
        'Europe/Berlin',
        'Europe/Dublin',
        'Europe/London',
        'Europe/Paris',
        'Europe/Zurich',
        'Pacific/Auckland',
        'Pacific/Honolulu',
    ],
    statesList: [
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AS', text: 'American Samoa' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District Of Columbia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'GU', text: 'Guam' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'MP', text: 'Northern Mariana Islands' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'PR', text: 'Puerto Rico' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VI', text: 'Virgin Islands' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
    ],
}
