<template>
    <div :class="$style.container">
        <div :class="$style.wrapper">
            <canvas ref="canvas" :id="chartId" width="300px"></canvas>
        </div>
        <div id="stackedChartLegend" class="legend__list" ref="legend"></div>
    </div>
</template>

<script>
import { reverse } from 'ramda'
import cloneDeep from 'lodash.clonedeep'
import Chart from 'chart.js/auto'
import { Tooltip } from 'chart.js'
import config from './stackedChartConfig'
import { createTooltip, generateRightPositioner } from '../tooltipConfig'

const assumedTooltipHeight = 40
const chartColours = [
    '#2167C4', // dark blue
    '#5593E8', // light blue
    '#01E6A5', // foam green
    '#008093', // deep green
    '#D68DEB', // violet
    '#FBCBBE', // peach pink
    '#F1B435', // yellow orange
    '#D38201', // ocre orange
    '#5C286B', // dark purple
    '#9553A8', // purple
    '#B6F6AF', // pale green
    '#00A87E', // green
    '#083572', // deep blue
    '#002B49', // midnight
]
const chartColourForDeclined = '#DCDFE4' // gray

export default {
    name: 'StackedChart',
    props: {
        chartId: String,
        chartData: Object,
    },
    mounted() {
        Chart.defaults.font.family = "'Poppins', sans-serif"
        Chart.defaults.font.size = '15'

        Tooltip.positioners.dropoffRight = generateRightPositioner(assumedTooltipHeight)

        createTooltip('dropoffChartTooltip')

        this.createStackedChart(this.chartId, this.chartData)
    },
    watch: {
        chartData: function refreshChart(newVal) {
            this.createStackedChart(this.chartId, newVal)
        },
    },
    data() {
        return {
            chart: null,
            chartLegend: null,
        }
    },
    computed: {
        defaultOptions() {
            return config.defaultOptions
        },
        chartColours() {
            return [
                '#2167C4',
                '#D38201',
                '#008093',
                '#00A87E',
                '#9553A8',
                '#5593E8',
                '#F1B435',
                '#39D6E3',
                '#01E6A5',
                '#D68DEB',
                '#FF6238',
                '#60CC54',
                '#083572',
                '#D93B11',
            ]
        },
        htmlLegendPlugin() {
            return {
                id: 'htmlLegend',
                afterUpdate(chart) {
                    const getOrCreateLegendList = (chartInstance, id) => {
                        const legendContainer = document.getElementById(id)
                        let listContainer = legendContainer.querySelector('ul')

                        if (!listContainer) {
                            listContainer = document.createElement('ul')
                            legendContainer.appendChild(listContainer)
                        }

                        return listContainer
                    }

                    const ul = getOrCreateLegendList(chart, 'stackedChartLegend')

                    // Remove old legend items
                    while (ul.firstChild) {
                        ul.firstChild.remove()
                    }

                    const legendItems = chart.options.plugins.legend.labels.generateLabels(chart)

                    legendItems.forEach((item) => {
                        const li = document.createElement('li')
                        li.style.alignItems = 'center'
                        li.style.display = 'flex'
                        li.style.flexDirection = 'row'
                        li.style.marginLeft = '10px'

                        const colourBox = document.createElement('span')
                        colourBox.style.background = item.fillStyle
                        colourBox.style.borderColor = item.strokeStyle
                        colourBox.style.borderWidth = `${item.lineWidth}px`
                        colourBox.style.display = 'inline-block'
                        colourBox.style.flexShrink = 0
                        colourBox.style.height = '15px'
                        colourBox.style.width = '15px'
                        colourBox.style.marginRight = '10px'

                        const textContainer = document.createElement('p')
                        textContainer.style.color = item.fontColor
                        textContainer.style.margin = 0
                        textContainer.style.padding = 0
                        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

                        const text = document.createTextNode(item.text)
                        textContainer.appendChild(text)

                        li.appendChild(colourBox)
                        li.appendChild(textContainer)
                        ul.appendChild(li)
                    })
                },
            }
        },
    },
    methods: {
        orderChartData(data) {
            const declineObject = data.datasets.find((element) => element.label === 'decline')

            if (!declineObject) {
                return data
            }

            const trimmedDatasets = data.datasets.filter((element) => element.label !== 'decline')

            return {
                ...data,
                datasets: [...trimmedDatasets, declineObject],
            }
        },
        applyStackColors(chartData) {
            return {
                ...chartData,
                datasets: reverse(
                    reverse(chartData.datasets).map((dataset, index) => ({
                        ...dataset,
                        backgroundColor:
                            dataset.label === 'decline'
                                ? chartColourForDeclined
                                : chartColours[index],
                    })),
                ),
            }
        },
        createStackedChart(chartId, data) {
            /* eslint-disable no-param-reassign */
            const chartParams = {
                type: 'bar',
                data: this.applyStackColors(this.orderChartData(data)),
                options: {
                    animation: {
                        duration: 0,
                    },
                    ...cloneDeep(this.defaultOptions),
                    containerId: 'stackedChartLegend',
                },
            }

            this.createChart(chartId, chartParams)
        },
        createChart(chartId, chartData) {
            const ctx = this.$refs.canvas

            if (this.chart) {
                this.chart.destroy()
            }

            this.chart = new Chart(ctx, {
                type: chartData.type,
                data: chartData.data,
                options: chartData.options,
                plugins: [this.htmlLegendPlugin],
            })

            return this.chart
        },
    },
}
</script>

<style>
.legend__list {
    display: flex;
    align-items: center;
}
</style>

<style module src="./StackedChart.css" />
